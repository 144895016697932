<template>
  <ExtendedView :tabs="tabs">
    <template v-slot:list="{ item }">
      <WidgetList
        :fixed-filters="fixedFilters(item.key)"
        :load-items-callback="getScheduleWidgets"
        :call-to-actions="callToActions"
        @selectWidget="selectWidget"
      />
    </template>
    <template v-slot:profile>
      <WidgetProfile
        v-if="selectedWidget"
        :widget="selectedWidget"
        :update-call-back="showEditWithPreview"
        :change-active-status-callback="updateWidget"
        widget-type="schedule"
        :preview-url="'https://schedule.salescloud.is?uuid=' + selectedWidget.uuid"
      />
    </template>
  </ExtendedView>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import WidgetProfile from "@/components/widget/WidgetProfile"
import WidgetList from "@/components/widget/WidgetList"

export default {
  name: "ScheduleWidgets",
  components: {
    WidgetList,
    WidgetProfile,
    ExtendedView
  },
  data() {
    return {
      scheduleWidgets: [],
      selectedWidget: null
    }
  },
  computed: {
    organization() {
      return this.$store.state.organization
    },
    activeCount() {
		return this.storeWidgets?.filter(widget => widget?.active).length ?? 0
    },
    inactiveCount() {
		return this.storeWidgets?.filter(widget => !widget?.active).length ?? 0
    },
    tabs() {
      return [
        {
          title: 'Active',
          key: 'active',
          badge: {
            content: this.activeCount
          }
        },
        {
          title: 'Inactive',
          key: 'inactive',
          badge: {
            content: this.inactiveCount
          }
        }
      ]
    },
    callToActions() {
      return [
        {
          title: this.$t('add'),
          callback: this.addNewWidget
        }
      ]
    },
  },
  methods: {
    selectWidget(widget) {
      this.selectedWidget = widget
    },
    fixedFilters(key) {
      switch (key) {
        case 'active':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'equals'
            }
          ]
        case 'inactive':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: false,
              operator: 'equals'
            }
          ]
        default:
          return []
      }
    },
    addNewWidget() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: {
          organization: this.organization.uuid,
          label: null,
          channel: null,
          location: null,
          items: null,
          categories: null,
          darkTheme: null,
          showImages: null,
          booking: null,
          defaultLanguage: null,
          hideExtraInfo: false,
          showUpsellItemsAsSwitch: false,
          mode: '0',
          mainButtonsRounded: false,
          mainButtonsCapitalized: false,
          mainButtonsColor: null,
          backgroundColor: null,
          textColor: null,
          primaryColor: null,
          minimalFooter: true,
          hideItemPrice: false,
          useSlotPrices: false,
          activitiesCategory: null,
          showAsCategories: false,
          intervalBetweenSlotsInMinutes: null,
          allowSelectionOfMultipleItems: false
        },
        formComponent: 'components/widget/ScheduleWidgetForm',
        saveCallback: this.createWidget,
        previewUrl: 'https://schedule.salescloud.is',
        title: this.$t('editWithPreview')
      })
    },
    createWidget(newWidget) {
      this.$store.dispatch('createScheduleWidget', newWidget).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('createdWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotCreateWidget')
          })
        }
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotCreateWidget')
        })
      })
    },
    showEditWithPreview(profile) {
      return this.$store.commit('updateDataToMutate', {
        objectToMutate: profile,
        formComponent: 'components/widget/ScheduleWidgetForm',
        saveCallback: this.updateWidget,
        previewUrl: 'https://schedule.salescloud.is',
        title: this.$t('editWithPreview')
      })
    },
    updateWidget(newValues) {
      this.updating = true
      return this.$store.dispatch('updateScheduleWidget', newValues).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('updatedWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotUpdateWidget')
          })
        }
        return result
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotUpdateWidget')
        })
      }).finally(() => {
        this.editMode = false
        this.updating = false
      })
    },
    getScheduleWidgets() {
      return this.$store.dispatch('getScheduleWidgets').then(result => {
        this.scheduleWidgets = result
        this.$store.commit('updateAppBarTabs', this.tabs)
        return result
      })
    }
  },
  watch: {
    scheduleWidgets(value) {
      if(typeof this.selectedWidget !== 'undefined' && this.selectedWidget !== null) {
        const widgetsIndex = value.findIndex(w => w && w.uuid === this.selectedWidget.uuid)
        if(widgetsIndex >= 0) {
          this.selectedWidget = value[widgetsIndex]
        }
      }
    },
    selectedAppBarTab(current, previous) {
      if(current !== previous) {
        this.selectedWidget = null
      }
    }
  },
  created() {
    this.getScheduleWidgets()

    this.$store.commit('updateAppBarTabs', this.tabs)
    if(Array.isArray(this.tabs) && this.tabs.length > 0) {
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  updated() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  destroyed() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', false)
    this.$store.commit('setMinimizeSidebar', true)
  }
}
</script>
